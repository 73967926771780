<template>
  <div class="excursions-view">
    <div class="excursions-view__wrapper">
      <Section>
        <HeroTop :backgroundImage="require(`@/assets/images/${image}`)">
          <template slot="hero-top-breadcrumbs">
            <BreadCrumbs :params="{navigation, color: 'rgba(255, 255, 255, .5)'}"/>
          </template>
          <template slot="hero-top-title">
            <h1 class="hero-top__title grid-lg-8">{{title}}</h1>
          </template>
          <template slot="hero-top-descriptions">
            <p class="hero-top__description grid-lg-8">{{description}}</p>
          </template>
        </HeroTop>
      </Section>
      <Section limiter>
        <Sort :type="sortType"
          v-if="!$mobile"
          :view="'cards-only'"
          v-on:change-sort-type="changeSortType"/>
        <div>
          <Row class="-m-15"
            wrap>
            <ExcursionCard :key="item.id"
              :likeTrip="true"
              :params="item"
              v-for="item in $excursions.data"/>
          </Row>
        </div>
        <Row v-if="$excursions.links.next">
          <Button v-on:click.native="nextPage"
            class="trips-view__more"
            color="like-link">{{ $t('service.show_more') }}
          </Button>
        </Row>
      </Section>
      <Section limiter>
        <Share :url="`${$host}/excursions`"
          :title="share.title"
          :description="share.description"/>
      </Section>
      <Section limiter>
        <Row :justify="!$mobile ? 'between' : 'start'"
          :wrap="!$laptop">
          <h2 class="trips-view__agencies-title">{{ $t('excursions_view.travel_firm') }}
            {{ city.accusative }} </h2>
          <a class="trips-view__agencies-add button button__main"
            :class="$mobile ? 'button__full' : ''"
            :href="addOrganizationOnKeycloak">
          {{ $t('excursions_view.add_org') }}</a>
        </Row>
      </Section>
      <Section v-if="$agencies && $agencies.length > 0"
        :limiter="!$mobile">
        <Row justify="between"
          :wrap="$tablet">
          <Column class="grid-sm-full grid-md-8 grid-lg-6">
            <List>
              <template slot="list-header">
                <span class="list__header-additions">{{ $t('excursions_view.found') }}
                  {{$agencies.length}}
                  {{plural($agencies.length, [`${$t('excursions_view.variants.p_1')}`,
                  `${$t('excursions_view.variants.p_2')}`,
                  `${$t('excursions_view.variants.p_3')}`])}}</span>
              </template>
              <template slot="list-content">
                <AgenciesGuidesAgency v-if="$operators.single"/>
                <AgenciesGuidesAgencies v-else/>
              </template>
            </List>
          </Column>
          <Column v-if="!$mobile"
            class="grid-md-8 grid-lg-6 relative">
            <OnMap :location="$operators.agency && $operators.agency.location || $city.location"
              :params="{sizeMap: {
                width: $laptop ? 'calc(50vw - 22px)' : '100%',
                height},}"
              :places="$operators.single ? [$operators.agency] : $operators.agencies"/>
          </Column>
        </Row>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExcursionsView',
  data() {
    return {
      trip: null,
      sortType: 'created_at',
      tripType: 'excursion',
      addOrganizationOnKeycloak:
          `${process.env.VUE_APP_DEFAULTH_ROOT_ACCOUNT_ON_KEYCLOAK}/account/restxq/organization-add`,
    };
  },
  computed: {
    city() {
      return this.$store.getters.GET_CITY.declensions;
    },
    meta() {
      return this?.$meta?.find((item) => item.slug === this.$city.slug);
    },
    image() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.excursions?.image
        || this.$meta.find((item) => item.slug === 'default')?.excursions?.image;
    },
    title() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.excursions?.title
          || this.$meta.find((item) => item.slug === 'default')?.excursions?.title;
    },
    description() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.excursions?.description
          || this.$meta.find((item) => item.slug === 'default')?.excursions?.description;
    },
    height() {
      let height;
      if (this.$laptop) height = '750px';
      if (this.$tablet) height = '405px';
      if (this.$mobile) height = '300px';
      return height;
    },
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/excursions',
        title: this.$i18n.t('menu.excursions'),
      }];
    },
  },
  created() {
    this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        count: 6,
        resolution: 'medium',
        city_id: this.$city.id,
        sort: this.sortType,
        type: this.tripType,
        lang: this.$i18n.locale,
      },
    });
    this.$store.dispatch('GET_AGENCIES_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        count: 9,
        resolution: 'medium',
        city_id: this.$city.id,
        lang: this.$i18n.locale,
      },
    });
    this.$store.dispatch('GET_GUIDES_FROM_SERVER');
    this.$store.dispatch('GET_TRIP_SUB_TYPES_FROM_SERVER');
  },
  methods: {
    changeSortType(type) {
      this.sortType = type;
      this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: {
          count: 6,
          resolution: 'medium',
          city_id: this.$city.id,
          sort: this.sortType,
          type: this.tripType,
          lang: this.$i18n.locale,
        },
      });
    },
    showAgency(id) {
      this.$store.commit('SET_AGENCY_TO_OPERATORS', id);
    },
    nextPage() {
      this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER_WITH_PARAMS', {
        params: {
          count: 6,
          resolution: 'medium',
          city_id: this.$city.id,
          sort: this.sortType,
          type: this.tripType,
          page: this.$excursions.links.next.split('?')[1].split('=')[1],
          lang: this.$i18n.locale,
        },
      });
    },
    plural(n, titles) {
      // eslint-disable-next-line max-len,no-nested-ternary
      return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    },
  },
};
</script>
